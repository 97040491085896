import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import LocalStorageService, { LocalStorageKey } from '@shared/services/local-storage.service';
import { UserRole } from '@shared/models/role';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    ) { }

  public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    if (this.authService.isLoggedIn()) {
      const roles = route.data.roles as UserRole[];

      if (!roles.length || roles.includes(this.authService.userRole)) {
        return true;
      }
    } else {
      if (route.url[0]) this.localStorageService.setItem(LocalStorageKey.AUTH_REDIRECT_ROUTE, JSON.stringify({
        url: route.url[0].path,
        params: route.queryParams,
      }));
    }

    return this.router.navigate(['login']);
  }
}
